<template>
<nav class="flex" id="breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-3 pb-3 border-gray-100 dark:border-gray-500 w-full">
    <li class="inline-flex items-center" v-for="(b,i) in data" :key='b'>
        <RouterLink :id="`${$route.name}_${i}_breadcrumb_link`" class="inline-flex items-center text-sm font-medium"  :to="{ name: b.link }" :class="{'primary-color hover:text-gray-900 dark:text-[#94A3B8]' :(i+1) === data.length, 'violet-color cursor-pointer' : (i+1) != data.length}">
          <svg v-if="(i!=0)" fill='#753ED7' viewBox='0 0 330 330' width='8' height='8' xmlns='http://www.w3.org/2000/svg' class="mr-3" style="transform: rotate(270deg);"><g><path d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z' /></g></svg>
          {{b.name}}
        </RouterLink>
  </li>
  </ol>
</nav>
</template>
<script>
export default {
  name: 'BreadcrumbComponent',
  props:{
      data:{
          data: Object,
      },
      isBgBlue: Boolean
  },
}
</script>